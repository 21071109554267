@mixin gw-contact-card {
    .gw-contact-card {
        border: 0;

        &--person {
            max-width: 28.125rem; // 450px
            padding: 0;
            text-align: center;
            width: fit-content;
        }

        &.gw-card:hover {
            box-shadow: none;
            transform: scale(1);
            transition: none;
        }

        .gw-card__title a {
            color: $color-black;
        }

        p {
            color: $color-text;
        }

        .gw-image {
            min-height: 9.375rem; // 150px
        }

        &-grid.gw-grid {
            gap: 0;
        }
    }
}
