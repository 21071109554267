@mixin gw-slider {
    .gw-slider {
        padding-block-end: $gutter-l + $gutter-xs;

        [data-button-type] {
            background-color: $color-brand;
            height: auto;
            inset-block-end: 0;
            inset-block-start: auto;

            .gw-icon {
                stroke: $color-text-alt;
                stroke-width: $gutter-xs / 2;
            }

            &:hover {
                background-color: $color-brand-alt-2;
                opacity: 1;

                .gw-icon {
                    stroke: $color-text-alt;
                }
            }
        }

        [data-button-type='next'] {
            @at-root {
                /* stylelint-disable max-nesting-depth */
                [dir='rtl'] & {
                    transform: translateX(-100%);
                }
                /* stylelint-enable max-nesting-depth */
            }
            inset-inline-end: 49.5%;
            transform: translateX(100%);
        }

        [data-button-type='previous'] {
            @at-root {
                /* stylelint-disable max-nesting-depth */
                [dir='rtl'] & {
                    transform: translateX(100%);
                }
                /* stylelint-enable max-nesting-depth */
            }
            inset-inline-end: auto;
            inset-inline-start: 49.5%;
            opacity: 0.7;
            transform: translateX(-100%);
        }
    }

    .gw-brand-list {
        &--slider {
            @include bp-small {
                /* stylelint-disable max-nesting-depth */
                .gw-slider {
                    &__slides {
                        display: flex;
                        flex-flow: column wrap;
                        margin-bottom: 2rem;
                        max-height: 25rem;

                        .gw-slider__slide {
                            /* stylelint-disable declaration-no-important */
                            flex: 0 0 50% !important; // Important used to override js inline addition only on this theme
                            min-height: 200px;
                            width: 50%;

                            &--brand {
                                height: 100%;
                                margin: auto 0;
                                width: 100%;
                            }
                            /* stylelint-enable declaration-no-important */
                        }
                    }
                }

                .gw-button-group {
                    display: flex;

                    [data-button-type='next'] {
                        inset-block-end: 2rem;
                    }

                    [data-button-type='previous'] {
                        inset-block-end: 2rem;
                    }
                }
                /* stylelint-enable max-nesting-depth */
            }
            position: relative;
        }
    }
}
