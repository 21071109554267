@mixin gw-navigation {
    .gw-navigation {
        @include bp-medium {
            .gw-navigation__dropdown {
                background-color: $color-brand;

                .gw-button-group {
                    background-color: $color-brand;
                }

                .gw-navigation__title {
                    color: $color-text-alt;
                    font-family: $font-family-title;
                    text-transform: uppercase;
                }

                .gw-navigation__list--nested {
                    grid-template-columns: repeat(
                        auto-fill,
                        minmax(15rem, 20rem)
                    );

                    /* stylelint-disable max-nesting-depth */
                    .gw-navigation__title {
                        color: $color-text-alt;
                        font-family: $font-family-title;
                    }

                    .gw-navigation__item {
                        padding-inline-start: 0;

                        .gw-navigation__link {
                            color: $color-grey-4;
                            font-family: $font-family;
                        }
                    }
                    /* stylelint-enable max-nesting-depth */
                }
            }
        }

        &__link {
            color: $color-brand;
            font-family: $font-family-title;

            &.gw-button[class*='--text'] {
                @include bp-medium {
                    padding: 0;
                }

                background-color: transparent;
                color: $color-brand;

                &:hover {
                    text-decoration: none;
                }
            }

            + .gw-button--icon {
                color: $color-brand;
            }

            &:hover {
                @include bp-medium {
                    color: $color-white;

                    /* stylelint-disable-next-line max-nesting-depth */
                    & + .gw-button--icon {
                        color: $color-white;
                    }
                }

                color: $color-brand;
                text-decoration: none;

                & + .gw-button--icon {
                    color: $color-brand;
                }
            }
        }

        [data-dropdown-open='true'] {
            @include bp-medium {
                &.gw-navigation__item {
                    background-color: $color-brand;
                }

                > .gw-navigation__link--has-submenu {
                    color: $color-white;

                    /* stylelint-disable-next-line max-nesting-depth */
                    + .gw-button--icon {
                        color: $color-white;
                    }
                }
            }
        }

        .gw-button {
            &:hover:not(:disabled) {
                opacity: 1;
            }
        }

        .gw-navigation__list {
            @at-root {
                [data-whatintent='mouse'] a:hover {
                    opacity: 1;
                }
            }

            &--nested {
                .gw-navigation__title {
                    color: $color-brand;
                    font-size: $font-size-m;
                }

                .gw-navigation__link {
                    color: $color-text;
                }
            }

            .gw-navigation__item {
                @include bp-medium {
                    padding-inline: $gutter-s;
                }

                &:hover {
                    // TODO: Is this needed?
                    /* stylelint-disable max-nesting-depth */
                    @include bp-medium {
                        background-color: $color-brand;

                        .gw-navigation__link {
                            background-color: $color-brand;
                            color: $color-white;

                            + .gw-button--icon {
                                color: $color-white;
                            }
                        }
                    }
                    /* stylelint-enable max-nesting-depth */
                }
            }
        }

        .show-mobile-nav {
            .gw-navigation__title {
                color: $color-white;
                font-family: $font-family-title;
            }

            .gw-navigation__link {
                background-color: $color-brand;
                color: $color-white;
                font-size: unset;
                padding: $nav-mobile-button-gutter;

                + .gw-button--icon {
                    color: $color-white;
                }

                &:hover {
                    background-color: $color-brand;
                    color: $color-white;

                    /* stylelint-disable-next-line max-nesting-depth */
                    + .gw-button--icon {
                        color: $color-white;
                    }
                }
            }

            .gw-navigation__list {
                background-color: $color-brand;

                .gw-button-group {
                    background-color: $color-brand;
                }

                .gw-navigation__item {
                    margin-block-end: 0;
                }

                &--nested {
                    /* stylelint-disable-next-line max-nesting-depth */
                    .gw-navigation__link {
                        font-family: $font-family;
                    }
                }
            }

            .gw-navigation__button--back {
                background-color: $color-brand;
                color: $color-white;

                &:hover {
                    background-color: $color-brand;
                    color: $color-white;
                    text-decoration: none;
                }
            }

            .gw-navigation__dropdown {
                background-color: $color-brand;
            }
        }

        & [id='toggle-mobile-nav'] {
            background-color: transparent;

            .gw-icon {
                fill: $color-brand-alt-2;
            }

            &.gw-button.gw-button--toggle:hover:not(:disabled) {
                background-color: transparent;
                transform: scale(1);
            }
        }
    }
}
