/*
----------------------------------------------------------------------------
NOTE:

DO NOT EDIT 'index.scss' directly.

Instead, make changes to '_index.scss.template', which will then be copied to 'index.scss' by Parcel bundler.
----------------------------------------------------------------------------
Default CSS variables.
----------------------------------------------------------------------------
*/
@use 'sass:math';
@import '_THEMES/ICDS/root-css-vars';

/*
----------------------------------------------------------------------------
THEME import goes before Sass variable declarations.
See https://robots.thoughtbot.com/sass-default
----------------------------------------------------------------------------
*/
@import '_THEMES/ICDS/ICDS';

/*
----------------------------------------------------------------------------
Base scaffold, containing all !default Sass variables.
----------------------------------------------------------------------------
*/
@import 'base';

/*
----------------------------------------------------------------------------
Fonts & globals.
----------------------------------------------------------------------------
*/
@import 'fonts';
@import 'globals';

@media screen {
    /*
    ----------------------------------------------------------------------------
    Components & pages.
    ----------------------------------------------------------------------------
    */
    @import 'multi-franchise';
    @import 'geronimo-site';
    @import 'components';
    @import 'pages';

    /*
    ----------------------------------------------------------------------------
    ShameCSS - for hacks.
    ----------------------------------------------------------------------------
    */
    @import 'shame';
}

@media print {
    @import 'print';
}

/*
----------------------------------------------------------------------------
Finally, pages that need specific treatment for print, but still need to inherit current theme styling in the browser (e.g. stock details).
----------------------------------------------------------------------------
*/
@import 'print-pages';
