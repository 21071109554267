@mixin gw-banner {
    .gw-banner--hero {
        .gw-banner {
            &__inner {
                @include bp-medium {
                    height: unset;
                }
                height: 70vh;

                .gw-image {
                    height: 100%;
                }
            }

            &__overlay {
                @include bp-medium {
                    max-width: 50%;
                }

                background-color: transparent;
                z-index: 1;

                &.is-hidden-on-medium {
                    position: absolute;
                }
            }

            &__text--large {
                @include bp-medium {
                    @include fluid-type-xxxl(
                        $min: $font-size-xl,
                        $ideal: calc(0.85rem + #{$fluid-type-target-xxxl}),
                        $max: $font-size-xxxl
                    );
                }

                font-family: $font-family-title;
                font-size: $font-size-xl;
            }
        }

        &.gw-banner__overlay {
            &--skin1:before {
                background: linear-gradient(
                    to right,
                    rgba(var(--themeColorBrandAlt2), 1),
                    rgba(34, 20, 65, 0.91),
                    rgba(var(--themeColorBrandAlt), 0.15),
                    rgba(var(--themeColorBrandAlt2), 1)
                );
                content: '';
                height: 100%;
                inset-block-start: 0;
                inset-inline-start: 0;
                position: absolute;
                width: 100%;
                z-index: 1;
            }
        }
    }
}
