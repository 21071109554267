@mixin gw-gallery {
    .gw-gallery {
        &.gw-gallery--portfolio.gw-slider {
            [data-button-type] {
                background-color: rgba(var(--themeGrey5), 0.6);
                opacity: 1;
            }
        }
    }
}
