@mixin gw-accordion {
    .gw-accordion {
        &__button {
            border: $border-width $border-style $color-grey-3;
            font-family: $font-family-bold;
            margin-block-end: $gutter-xs;
            padding: $gutter-s $gutter;

            &[aria-expanded='true'] {
                border-block-end: 0;
                margin-block-end: 0;
            }

            .gw-icon {
                fill: $color-brand-alt-2;
            }
        }

        &__content {
            border: $border-width $border-style $color-grey-3;
            border-block-start: 0;
            margin-block-end: $gutter-xs;
            padding-block-end: $gutter-xs;
            padding-block-start: 0;
        }
    }
}
