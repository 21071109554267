@mixin gw-footer {
    .gw-footer {
        background-color: $color-footer-background;

        &__link {
            &.gw-button--whatsapp {
                color: $color-footer-background-font;
            }
        }

        &--supplementary {
            background-color: $color-footer-supplementary;

            .gw-social {
                &__title {
                    color: $color-footer-social-font;
                }
            }
        }

        &--mobile {
            background-color: $color-footer-background;

            .gw-accordion {
                /* stylelint-disable max-nesting-depth */
                &__button {
                    @at-root {
                        [data-whatintent='mouse'] &:hover,
                        [data-whatintent='keyboard'] &:focus {
                            color: $color-grey-3;
                        }
                    }

                    border-block-start: 0;
                    border-inline-end: 0;
                    border-inline-start: 0;
                    color: $color-footer-background-font;

                    &__inner {
                        text-align: start;
                    }

                    .gw-icon {
                        fill: $color-white;
                    }
                }
                /* stylelint-enable max-nesting-depth */
                &__content {
                    border: 0;
                    padding-block-start: $gutter-s;
                }
            }

            .gw-footer__group-content > *:first-child {
                padding-block-start: calc($gutter-s / 2);
            }

            .gw-footer--supplementary {
                .gw-footer__item,
                .gw-footer__link {
                    color: $color-white;
                }
            }
        }

        &__group-title {
            color: $color-footer-background-font;
        }
    }
}
