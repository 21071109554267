@mixin gw-tabbed-slider {
    .gw-tabbed-slider {
        &__wrap {
            background-image: url('/static/GWDS/gc-tab-bg.png');
        }

        &__title {
            p {
                color: $color-grey-3;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                color: $color-text-alt;
            }
        }

        &__button {
            border-color: $color-white;
            color: $color-text-alt;

            &.is-selected,
            &:hover {
                span {
                    color: $color-grey-3;
                }
            }

            a:not([class]) {
                color: $color-text-alt;
            }
        }
    }
}
