/*
----------------------------------------------------------------------------
CSS variables for dynamic theming.

- Color values are specified as a comma-separated list of RGB values. This allows us to leverage the Sass 'rgba()' function.
- Theme overrides can be applied via the CMS, rendered in HTML <style> blocks.
- Legacy browsers can only leverage CSS variables if they are defined inside ':root' element.
----------------------------------------------------------------------------
*/

:root {
    --themeColorBrand: 195, 32, 38;
    --themeColorBackground: 255, 255, 255;
    --themeColorBackgroundAlt: 251, 250, 253;
    --themeColorText: 21, 21, 21;
    --themeColorTextAlt: 255, 255, 255;
    --themeColorTextSecondary: 85, 85, 85;
    --themeColorTextSecondaryAlt: 231, 231, 231;
    --themeColorLink: 195, 32, 28;
    --themeColorLinkAlt: 175, 14, 17;

    --themeBlack: 0, 0, 0;
    --themeWhite: 255, 255, 255;
    --themeGrey1: 247, 247, 247;
    --themeGrey2: 236, 236, 236;
    --themeGrey3: 196, 196, 196;
    --themeGrey4: 42, 42, 42;
    --themeGrey5: 21, 21, 21;

    --themeAccentPositive: 0, 130, 0;
    --themeAccentPositiveAlt: 51, 155, 51;
    --themeAccentNegative: 175, 14, 17;
    --themeAccentNegativeAlt: 226, 63, 74;
    --themeAccentNeutral: 177, 98, 1;
    --themeAccentNeutralAlt: 221, 122, 1;

    --themeColorHeader: 255, 255, 255;
    --themeColorFooter: 42, 42, 42;
    --themeColorFooterFont: 255, 255, 255;
    --themeColorFooterSocial: 42, 42, 42;
    --themeColorSocialFont: 255, 255, 255;
    --themeColorSupplementary: 42, 42, 42;
    --themeColorSupplementaryFont: 255, 255, 255;
    --themeColorStickyBackground: 42, 42, 42;

    --themeFontFamily: RobotoRegular;
    --themeFontFamilyBold: RobotoBold;
    --themeFontFamilyTitle: RobotoBold;
    --themeFontFamilyArabic: RobotoRegular;
    --themeFontFamilyArabicTitle: RobotoBold;
    --themeFontFamilyVietnamese: RobotoRegular;
    --themeFontFamilyVietnameseTitle: RobotoBold;
    --themeFontSizeSmall: 75%;
    --themeFontSize: 100%;
    --themeFontSize2K: 110%;
    --themeFontSize4K: 200%;

    --themeMaxLineLength: 85ch;
    --themeMaxContentWidth: 65rem;

    --themeMediaAspectRatio: 16/9;
}
