@mixin gw-breadcrumb {
    .gw-breadcrumb {
        &__item {
            &:not(:last-child)::after {
                color: $color-brand;
            }
        }

        &__link {
            color: $color-brand-alt-2;
        }
    }
}
