@mixin gw-filter-sidebar {
    .gw-filter-sidebar {
        &__accordion {
            &-content {
                .gw-filter-sidebar__column {
                    /* stylelint-disable max-nesting-depth */
                    .gw-form__label {
                        > span {
                            color: $color-grey-5;
                        }
                    }
                    /* stylelint-enable max-nesting-depth */
                }
            }
        }
    }
}
