@mixin gw-button {
    .gw-button {
        /*stylelint-disable max-nesting-depth */
        &[class*='--text'] {
            @include bp-medium {
                padding: $gutter-s $gutter-l;
            }

            border: none;
            color: $color-brand;
            padding: $gutter-xs;

            &:hover:not(:disabled) {
                text-decoration: underline;
            }

            &.gw-button {
                &--floating {
                    font-family: $font-family;

                    .gw-button__inner > span {
                        text-transform: capitalize;
                    }
                }

                &--primary,
                &--secondary,
                &--positive,
                &--negative,
                &--toggle {
                    @at-root [data-whatintent='keyboard'] &:focus {
                        box-shadow: 0 0 0 0.25rem $color-neutral;
                    }
                }

                &--primary,
                &--positive,
                &--floating {
                    background-color: $color-brand;
                    border-color: $color-brand;
                    color: $color-white;

                    &:hover:not(:disabled) {
                        background-color: $color-link-alt;
                        border-color: $color-link-alt;
                        opacity: 1;
                        text-decoration: none;
                    }
                }

                &.is-selected {
                    background-color: $color-brand;
                    border-color: $color-brand;
                    color: $color-white;

                    &:hover:not(:disabled) {
                        background-color: $color-brand;
                        border-color: $color-brand;
                        color: $color-white;
                    }
                }

                &--secondary,
                &--negative,
                &--toggle {
                    background-color: transparent;
                    border: $border-style $border-width $color-grey-5;
                    color: $color-text;

                    &:hover:not(:disabled) {
                        background-color: rgba(var(--themeGrey5), 0.1);
                        border-color: $color-grey-5;
                        text-decoration: none;
                    }
                }
            }
        }
        /*stylelint-enable max-nesting-depth */
        &:disabled,
        &:disabled:hover {
            opacity: 0.3;
        }
    }
}
