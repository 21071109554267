@mixin gw-typography {
    h1 {
        @include fluid-type-xxxl(
            $min: $font-size-xl,
            $ideal: calc(0.85rem + #{$fluid-type-target-xxxl}),
            $max: $font-size-xxxl
        );
    }

    h2 {
        @include fluid-type-xxl(
            $min: $font-size-l,
            $ideal: calc(0.85rem + #{$fluid-type-target-xxl}),
            $max: $font-size-xxl
        );
    }

    h3,
    big {
        @include fluid-type-xl(
            $min: $font-size-m,
            $ideal: calc(0.85rem + #{$fluid-type-target-xl}),
            $max: $font-size-xl
        );
    }

    h4 {
        @include fluid-type-l(
            $min: $font-size,
            $ideal: calc(0.85rem + #{$fluid-type-target-l}),
            $max: $font-size-l
        );
    }

    body,
    h5,
    p {
        @include fluid-type-default(
            $min: $font-size,
            $ideal: calc(0.85rem + #{$fluid-type-target}),
            $max: $font-size-m
        );
    }

    h6 {
        font-size: inherit;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $color-brand-alt-2;
        letter-spacing: 0.01875rem;

        > a {
            color: $color-brand;
        }
    }

    /* stylelint-disable no-descending-specificity */
    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

        &:not([class]) {
            color: $color-brand;
        }
    }

    p {
        line-height: $line-height-xs;
    }

    /* stylelint-enable no-descending-specificity */
    [aria-current] {
        color: $color-brand;
        font-weight: normal;
    }
}
