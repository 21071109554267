@mixin gw-product-search-results {
    .gw-product-search-results {
        &--stock {
            .gw-product-search-results__filters {
                .gw-button.gw-filter-sidebar__toggle {
                    /* stylelint-disable max-nesting-depth */
                    &:hover {
                        @include bp-medium {
                            background-color: transparent;
                            color: $color-brand;
                        }
                    }
                    /* stylelint-enable max-nesting-depth */
                }
            }
        }
    }
}
