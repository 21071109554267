/*
----------------------------------------------------------------------------
@font-face rules.

- 'font-display: swap;' ensures that all text will be rendered with a fallback system font until custom font is ready.
----------------------------------------------------------------------------
*/

@font-face {
    font-display: swap;
    font-family: 'RobotoRegular';
    font-style: normal;
    font-weight: normal;
    src: url('./_THEMES/ICDS/fonts/roboto-regular/roboto-regular.woff2')
            format('woff2'),
        url('./_THEMES/ICDS/fonts/roboto-regular/roboto-regular.woff')
            format('woff'),
        url('./_THEMES/ICDS/fonts/roboto-regular/roboto-regular.ttf')
            format('truetype');
}

@font-face {
    font-display: swap;
    font-family: 'RobotoBold';
    font-style: normal;
    font-weight: normal;
    src: url('./_THEMES/ICDS/fonts/roboto-bold/roboto-bold.woff2')
            format('woff2'),
        url('./_THEMES/ICDS/fonts/roboto-bold/roboto-bold.woff') format('woff'),
        url('./_THEMES/ICDS/fonts/roboto-bold/roboto-bold.ttf')
            format('truetype');
}
