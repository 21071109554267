@mixin gw-form {
    .gw-form {
        &__error,
        [class*='checkbox'],
        [class*='radio'] {
            font-size: $font-size-s;
        }

        &__select {
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" style="fill: rgb(31, 23, 50)"><path d="M20,40l2.8-2.8L50,64.3l27.2-27.1L80,40L50,70L20,40z"/></svg>');
            padding: 0 $gutter-s;
        }

        &__input,
        &__select,
        &__textarea {
            @at-root [data-whatintent='mouse'] &:focus {
                border: none;
                border-block-end: $border-width-m $border-style $color-grey-5;
                box-shadow: none;
                outline: none;
                transition: 0.3s ease-in-out;
            }

            border: none;
            border-block-end: $border-width-m $border-style $color-grey-2;
            padding-inline-start: 0;

            &:invalid:required:focus {
                border-block-end: $border-width-m $border-style $color-negative;
                box-shadow: none;
            }
        }
    }
}
